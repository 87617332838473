<template>
  <div class="add-vid">
    <div class="box">
      <div class="inpBox">
        <div class="key">Youtube链接:</div>
        <input
          type="text"
          v-model="form.url"
          @change="jxVideoDec(form.url)"
          placeholder="请输入正规Youtube链接"
        />
      </div>
      <div class="inpBox">
        <div class="key">标题:</div>
        <input type="text" v-model="form.title" placeholder="不超过18个字" />
      </div>
      <div class="inpBox">
        <div class="key">视频分类:</div>
        <el-select v-model="form.state" placeholder="请选视频分类">
          <el-option
            :label="item.title"
            :value="item.id"
            v-for="(item, index) in stateList"
            :key="index"
          ></el-option>
        </el-select>
      </div>
      <div class="imgBox">
        <el-upload
          class="avatar-uploader"
          :action="uploadURL"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="form.img" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"> 点击上传封面 </i>
        </el-upload>
      </div>
      <div class="tips">
        <span>推荐尺寸：375* 211</span>
        <span>不超过2M</span>
      </div>
      <div class="useYoutub">
        <span>使用 Youtub 信息</span>
        <el-switch
          v-model="value"
          active-color="#FD5391"
          inactive-color="#dcdee0"
        >
        </el-switch>
      </div>
      <div class="butBox">
        <div class="but">确定上传</div>
        <div class="but but2">确定上传</div>
      </div>
      <!-- <van-button type="primary">主要按钮</van-button> -->
    </div>
  </div>
</template>
<script>
import Store from "../../store";
// import word from "../../sensitiveWord";
export default {
  name: "upload",
  mounted() {
    // console.log(word("18禁"));
    // console.log(word("共产党"));
    // 获取视频分类
    this.getVideoStateList();
  },
  data() {
    return {
      // 是否显示
      dialogVisible: false,
      store: Store.state,
      uti: Store.uti,
      dialogTableVisible: false,
      dialogFormVisible: false,
      // 链接地址是否可以输入
      ifDis: false,
      // 视频分类表
      stateList: [],
      form: {
        // 视频链接
        url: "",
        // 视频标题
        title: "",
        // 视频分类
        state: "",
        // 封面
        img: "",
        // 是否使用utb封面
        utbImgIf: true,
      },
      loading: false,
      formLabelWidth: "120px",

      test: {
        // 封面
        thumbnail:
          "https://i.ytimg.com/vi_webp/riJYZZIybcc/sddefault.webp?v=6286e40a",
        // 标题
        title:
          "💥拜登訪亞抗共，黨官慌忙吹風；北京全面封城，次生災害危機出現；胡錫進再挑戰清零，上海「人命如螻蟻」；海外資產影響晉升，習近平向全黨宣戰？新規助力20大？中共在醞釀攻台？【新聞看點 李沐陽 05.20】",
        // 视频格式
        videoFormat: { adaptive: false },
        // 视频链接
        videoUrl:
          "https://rr3---sn-npoeen76.googlevideo.com/videoplayback?expire=1653037724&ei=PAaHYqiyA7KY4t4P25WByAk&ip=43.134.60.8&id=o-ADeKW9qgYPOQSajnU86PEucHjkEycr1EebNmeg41HHFj&itag=22&source=youtube&requiressl=yes&mh=MQ&mm=31%2C26&mn=sn-npoeen76%2Csn-30a7yne7&ms=au%2Conr&mv=m&mvi=3&pl=18&initcwndbps=395000&vprv=1&mime=video%2Fmp4&cnr=14&ratebypass=yes&dur=2030.074&lmt=1653006424412473&mt=1653015658&fvip=2&fexp=24001373%2C24007246&c=ANDROID&txp=3318224&sparams=expire%2Cei%2Cip%2Cid%2Citag%2Csource%2Crequiressl%2Cvprv%2Cmime%2Ccnr%2Cratebypass%2Cdur%2Clmt&sig=AOq0QJ8wRQIgWSye4TN5wPVoEjDP3CSTKy0EF4sQizgzu0rRA7BYvA4CIQDT0IYhcudpT7HTX0PWIbqMgGEj3vXnvfIQ7z_6yF6FtQ%3D%3D&lsparams=mh%2Cmm%2Cmn%2Cms%2Cmv%2Cmvi%2Cpl%2Cinitcwndbps&lsig=AG3C_xAwRQIgOOrVQGSm3-KI2D4A3j9quiK9ijHZCE780fC0KbdUR2ECIQDp6ZVoyGhr-qd70AERIGfVhP1fSf5HaNUcueUEuG9utg%3D%3D",
      },
    };
  },
  methods: {
    up() {
      // this.ifShow = true;
      this.dialogVisible = true;
    },
    down() {
      // this.ifShow = false;
      this.form.url = "";
      this.form.state = "";
      this.form.title = "";
      this.form.img = "";
      this.ifDis = false;
      this.dialogVisible = false;
    },
    // 解析用户上传视频信息
    async jxVideoDec(url) {
      this.loading = true;
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.post(`/videoInfo/parseYoutube`, {
        url: url,
      });
      this.loading = false;
      if (res.code != 200) {
        this.down();
        return this.$message.error("视频解析失败，请重试");
      }
      this.ifDis = true;
      this.form.title = res.data.title;
      this.form.img = res.data.thumbnail;
    },
    // 获取视频分类
    async getVideoStateList() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.get(`/profileTopic/topics/${1}`, {});
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      /*
        id: "1"
        sortNum: 1
        status: 1
        title: "什么是psc?"
        typeCode: 1
        */
      this.store.topics = res.data;
      this.stateList = res.data;
      // this.form.state = this.stateList[0].id;
    },
    // 提交视频
    async upLoadVideo() {
      // if (!word(this.form.title)) {
      //   return this.$message.error("标题包含敏感词汇");
      // }

      if (!this.store.user.userId) {
        return this.$message.error("请先登录");
      }
      if (!this.form.url) {
        return this.$message.error("请输入视频链接");
      }
      if (!this.form.title) {
        return this.$message.error("请输入视频标题");
      }
      if (!this.form.state) {
        return this.$message.error("选择分类");
      }
      let { data: res } = await this.$http.post("/videoInfo/addVideo", {
        //分类
        topicId: this.form.state,
        // 用户id
        userId: this.store.user.userId,
        // 封面
        videoThumbnail: this.form.img,
        // 视频标题
        videoTitle: this.form.title,
        // 视频链接
        youtubeUrl: this.form.url,
      });

      if (res.code !== 200) {
        return this.$message.error(res.message);
      }

      this.form.url = "";
      this.form.img = "";
      this.form.title = "";
      this.down();
      this.$message.success("提交成功");
    },

    handleAvatarSuccess(res, file) {
      this.form.img = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.add-vid {
  margin-top: (40 /3.75vw);
  margin-bottom: (90 / 3.75vw);
  .box {
    margin: 0 (18 / 3.75vw);
    font-size: (12 / 3.75vw);
    .inpBox {
      // display: flex;
      border-bottom: 1px solid #d7d7d7;
      padding: (10 / 3.75vw) 0;
      margin-bottom: (20 / 3.75vw);
      .key {
        /* Youtube链接： */
        width: (97 / 3.75vw);
        height: (20 / 3.75vw);
        font-family: PingFang SC Medium;
        font-size: (14 / 3.75vw);
        font-weight: normal;
        letter-spacing: 0em;
        color: #333;
      }
      input {
        width: 100%;
        min-width: (10 / 3.75vw);
        margin-top: (9 / 3.75vw);
        // padding-left: (15 / 3.75vw);
        border: none;
        /* 不超过18个字 */
        height: (22 / 3.75vw);
        font-family: PingFang SC Regular;
        font-size: (16 / 3.75vw);
        font-weight: normal;
        background: none;
        letter-spacing: 0em;
        color: #333;
      }
    }
    .imgBox {
      // height: (211 / 3.75vw);
      video {
        width: 100%;
      }
      /deep/.avatar-uploader .el-upload {
        border: 1px solid #cdcdcd;
        border-radius: (6 / 3.75vw);
        width: 100%;
        height: (211 / 3.75vw);
        cursor: pointer;
        position: relative;
        overflow: hidden;
        margin: (10 / 3.75vw) 0 0;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: (28 / 3.75vw);
        color: #8c939d;
        border: 1px solid #cdcdcd;
        width: 100%;
        border-radius: (5 / 3.75vw);
        line-height: (211 / 3.75vw);
        margin: (10 / 3.75vw) 0;
        // border: 1px solid #000;
        text-align: center;
      }
      .avatar {
        width: (600 / 3.75vw);
        height: (211 / 3.75vw);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
      }
    }
    .tips {
      display: flex;
      justify-content: space-between;
      margin-bottom: (10 / 3.75vw);
      span {
        /* 使用youtub封面： */

        height: (20 / 3.75vw);
        font-family: PingFang SC Medium;
        font-size: (10 / 3.75vw);
        font-weight: normal;
        letter-spacing: 0em;
        color: #666666;
      }
    }
    .useYoutub {
      display: flex;
      justify-content: space-between;
      span {
        /* 使用youtub封面： */

        height: (20 / 3.75vw);
        font-family: PingFang SC Medium;
        font-size: (14 / 3.75vw);
        font-weight: normal;
        letter-spacing: 0em;
        color: #666666;
      }
    }
    .butBox {
      text-align: center;
      .but {
        margin-top: (40 / 3.75vw);
        display: inline-block;
        width: (315 / 3.75vw);
        height: (42 / 3.75vw);
        line-height: (42 / 3.75vw);
        border-radius: (104 / 3.75vw);
        background: linear-gradient(
          90deg,
          #fd5392 0%,
          #fd5392 0%,
          #f86f64 100%,
          #f86f64 100%
        );
        box-shadow: 0px 2px 3px 0px rgba(176, 176, 176, 0.5);
        font-family: PingFang SC Medium;
        font-size: (14 / 3.75vw);
        font-weight: normal;
        letter-spacing: 0em;
        color: #ffffff;
        /* 矩形 4 */
        width: (335 / 3.75vw);
        height: (42 / 3.75vw);
        border-radius: 5px;
        background: #ff7d41;
        box-shadow: 0px 2px 3px 0px rgba(176, 176, 176, 0.5);
      }
      .but2 {
        background: linear-gradient(90deg, #999999 0%, #999999 100%);
      }
    }
  }
}
</style>
